<template>
  <div class="group-politic">
    <div class="group-politic-title">
      <span>Nuestras políticas</span>
    </div>
    <div class="group-politic-items">
      <div class="group-politic-items-option" @click="showPolitic(0)">
        <div class="group-politic-items-option-logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="66" height="80" viewBox="0 0 66 80" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M43.5404 0.468001L65.5404 22.868C65.6862 23.0167 65.8019 23.1932 65.8808 23.3874C65.9597 23.5817 66.0002 23.7898 66 24V75.2C66 76.473 65.5033 77.6939 64.6192 78.5941C63.7351 79.4943 62.536 80 61.2857 80H4.71429C3.46398 80 2.26488 79.4943 1.38078 78.5941C0.496682 77.6939 0 76.473 0 75.2V4.8C0 3.52696 0.496682 2.30606 1.38078 1.40589C2.26488 0.505714 3.46398 4.88872e-07 4.71429 4.88872e-07H42.4286C42.635 -0.000163801 42.8394 0.0410819 43.0302 0.121383C43.221 0.201684 43.3943 0.319466 43.5404 0.468001ZM60.6336 22.4L44 5.46V22.4H60.6336ZM4.71429 76.8H61.2857C61.7025 76.8 62.1022 76.6314 62.3969 76.3314C62.6916 76.0313 62.8571 75.6244 62.8571 75.2V25.6H42.4286C42.0118 25.6 41.6121 25.4314 41.3174 25.1314C41.0227 24.8313 40.8571 24.4243 40.8571 24V3.2H4.71429C4.29752 3.2 3.89782 3.36857 3.60312 3.66863C3.30842 3.96869 3.14286 4.37566 3.14286 4.8V75.2C3.14286 75.6244 3.30842 76.0313 3.60312 76.3314C3.89782 76.6314 4.29752 76.8 4.71429 76.8ZM31.915 62.0357C32.198 62.3322 32.5901 62.5 33 62.5C33.4099 62.5 33.802 62.3322 34.085 62.0357L43.085 52.6071C43.657 52.0079 43.635 51.0584 43.0357 50.4864C42.4365 49.9144 41.487 49.9365 40.915 50.5357L34.5 57.2562V39C34.5 38.1716 33.8284 37.5 33 37.5C32.1716 37.5 31.5 38.1716 31.5 39L31.5 57.2562L25.085 50.5357C24.513 49.9365 23.5635 49.9144 22.9643 50.4864C22.365 51.0584 22.343 52.0079 22.915 52.6071L31.915 62.0357Z" fill="black"/>
          </svg>
        </div>
        <div class="group-politic-items-option-text">
          <span>Política de Protección de Datos HABEAS DATA</span>
        </div>
      </div>
      <div class="group-politic-items-option" @click="showPolitic(1)">
        <div class="group-politic-items-option-logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="66" height="80" viewBox="0 0 66 80" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M43.5404 0.468001L65.5404 22.868C65.6862 23.0167 65.8019 23.1932 65.8808 23.3874C65.9597 23.5817 66.0002 23.7898 66 24V75.2C66 76.473 65.5033 77.6939 64.6192 78.5941C63.7351 79.4943 62.536 80 61.2857 80H4.71429C3.46398 80 2.26488 79.4943 1.38078 78.5941C0.496682 77.6939 0 76.473 0 75.2V4.8C0 3.52696 0.496682 2.30606 1.38078 1.40589C2.26488 0.505714 3.46398 4.88872e-07 4.71429 4.88872e-07H42.4286C42.635 -0.000163801 42.8394 0.0410819 43.0302 0.121383C43.221 0.201684 43.3943 0.319466 43.5404 0.468001ZM60.6336 22.4L44 5.46V22.4H60.6336ZM4.71429 76.8H61.2857C61.7025 76.8 62.1022 76.6314 62.3969 76.3314C62.6916 76.0313 62.8571 75.6244 62.8571 75.2V25.6H42.4286C42.0118 25.6 41.6121 25.4314 41.3174 25.1314C41.0227 24.8313 40.8571 24.4243 40.8571 24V3.2H4.71429C4.29752 3.2 3.89782 3.36857 3.60312 3.66863C3.30842 3.96869 3.14286 4.37566 3.14286 4.8V75.2C3.14286 75.6244 3.30842 76.0313 3.60312 76.3314C3.89782 76.6314 4.29752 76.8 4.71429 76.8ZM31.915 62.0357C32.198 62.3322 32.5901 62.5 33 62.5C33.4099 62.5 33.802 62.3322 34.085 62.0357L43.085 52.6071C43.657 52.0079 43.635 51.0584 43.0357 50.4864C42.4365 49.9144 41.487 49.9365 40.915 50.5357L34.5 57.2562V39C34.5 38.1716 33.8284 37.5 33 37.5C32.1716 37.5 31.5 38.1716 31.5 39L31.5 57.2562L25.085 50.5357C24.513 49.9365 23.5635 49.9144 22.9643 50.4864C22.365 51.0584 22.343 52.0079 22.915 52.6071L31.915 62.0357Z" fill="black"/>
          </svg>
        </div>
        <div class="group-politic-items-option-text">
          <span>Política de Sostenibilidad</span>
        </div>
      </div>
      <div class="group-politic-items-option" @click="showPolitic(2)">
        <div class="group-politic-items-option-logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="66" height="80" viewBox="0 0 66 80" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M43.5404 0.468001L65.5404 22.868C65.6862 23.0167 65.8019 23.1932 65.8808 23.3874C65.9597 23.5817 66.0002 23.7898 66 24V75.2C66 76.473 65.5033 77.6939 64.6192 78.5941C63.7351 79.4943 62.536 80 61.2857 80H4.71429C3.46398 80 2.26488 79.4943 1.38078 78.5941C0.496682 77.6939 0 76.473 0 75.2V4.8C0 3.52696 0.496682 2.30606 1.38078 1.40589C2.26488 0.505714 3.46398 4.88872e-07 4.71429 4.88872e-07H42.4286C42.635 -0.000163801 42.8394 0.0410819 43.0302 0.121383C43.221 0.201684 43.3943 0.319466 43.5404 0.468001ZM60.6336 22.4L44 5.46V22.4H60.6336ZM4.71429 76.8H61.2857C61.7025 76.8 62.1022 76.6314 62.3969 76.3314C62.6916 76.0313 62.8571 75.6244 62.8571 75.2V25.6H42.4286C42.0118 25.6 41.6121 25.4314 41.3174 25.1314C41.0227 24.8313 40.8571 24.4243 40.8571 24V3.2H4.71429C4.29752 3.2 3.89782 3.36857 3.60312 3.66863C3.30842 3.96869 3.14286 4.37566 3.14286 4.8V75.2C3.14286 75.6244 3.30842 76.0313 3.60312 76.3314C3.89782 76.6314 4.29752 76.8 4.71429 76.8ZM31.915 62.0357C32.198 62.3322 32.5901 62.5 33 62.5C33.4099 62.5 33.802 62.3322 34.085 62.0357L43.085 52.6071C43.657 52.0079 43.635 51.0584 43.0357 50.4864C42.4365 49.9144 41.487 49.9365 40.915 50.5357L34.5 57.2562V39C34.5 38.1716 33.8284 37.5 33 37.5C32.1716 37.5 31.5 38.1716 31.5 39L31.5 57.2562L25.085 50.5357C24.513 49.9365 23.5635 49.9144 22.9643 50.4864C22.365 51.0584 22.343 52.0079 22.915 52.6071L31.915 62.0357Z" fill="black"/>
          </svg>
        </div>
        <div class="group-politic-items-option-text">
          <span>Política de Seguridad de la Información - TIC</span>
        </div>
      </div>
      <div class="group-politic-items-option" @click="showPolitic(3)">
        <div class="group-politic-items-option-logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="66" height="80" viewBox="0 0 66 80" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M43.5404 0.468001L65.5404 22.868C65.6862 23.0167 65.8019 23.1932 65.8808 23.3874C65.9597 23.5817 66.0002 23.7898 66 24V75.2C66 76.473 65.5033 77.6939 64.6192 78.5941C63.7351 79.4943 62.536 80 61.2857 80H4.71429C3.46398 80 2.26488 79.4943 1.38078 78.5941C0.496682 77.6939 0 76.473 0 75.2V4.8C0 3.52696 0.496682 2.30606 1.38078 1.40589C2.26488 0.505714 3.46398 4.88872e-07 4.71429 4.88872e-07H42.4286C42.635 -0.000163801 42.8394 0.0410819 43.0302 0.121383C43.221 0.201684 43.3943 0.319466 43.5404 0.468001ZM60.6336 22.4L44 5.46V22.4H60.6336ZM4.71429 76.8H61.2857C61.7025 76.8 62.1022 76.6314 62.3969 76.3314C62.6916 76.0313 62.8571 75.6244 62.8571 75.2V25.6H42.4286C42.0118 25.6 41.6121 25.4314 41.3174 25.1314C41.0227 24.8313 40.8571 24.4243 40.8571 24V3.2H4.71429C4.29752 3.2 3.89782 3.36857 3.60312 3.66863C3.30842 3.96869 3.14286 4.37566 3.14286 4.8V75.2C3.14286 75.6244 3.30842 76.0313 3.60312 76.3314C3.89782 76.6314 4.29752 76.8 4.71429 76.8ZM31.915 62.0357C32.198 62.3322 32.5901 62.5 33 62.5C33.4099 62.5 33.802 62.3322 34.085 62.0357L43.085 52.6071C43.657 52.0079 43.635 51.0584 43.0357 50.4864C42.4365 49.9144 41.487 49.9365 40.915 50.5357L34.5 57.2562V39C34.5 38.1716 33.8284 37.5 33 37.5C32.1716 37.5 31.5 38.1716 31.5 39L31.5 57.2562L25.085 50.5357C24.513 49.9365 23.5635 49.9144 22.9643 50.4864C22.365 51.0584 22.343 52.0079 22.915 52.6071L31.915 62.0357Z" fill="black"/>
          </svg>
        </div>
        <div class="group-politic-items-option-text">
          <span>Política de Proveedores</span>
        </div>
      </div>
      <div class="group-politic-items-option" @click="showPolitic(4)">
        <div class="group-politic-items-option-logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="66" height="80" viewBox="0 0 66 80" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M43.5404 0.468001L65.5404 22.868C65.6862 23.0167 65.8019 23.1932 65.8808 23.3874C65.9597 23.5817 66.0002 23.7898 66 24V75.2C66 76.473 65.5033 77.6939 64.6192 78.5941C63.7351 79.4943 62.536 80 61.2857 80H4.71429C3.46398 80 2.26488 79.4943 1.38078 78.5941C0.496682 77.6939 0 76.473 0 75.2V4.8C0 3.52696 0.496682 2.30606 1.38078 1.40589C2.26488 0.505714 3.46398 4.88872e-07 4.71429 4.88872e-07H42.4286C42.635 -0.000163801 42.8394 0.0410819 43.0302 0.121383C43.221 0.201684 43.3943 0.319466 43.5404 0.468001ZM60.6336 22.4L44 5.46V22.4H60.6336ZM4.71429 76.8H61.2857C61.7025 76.8 62.1022 76.6314 62.3969 76.3314C62.6916 76.0313 62.8571 75.6244 62.8571 75.2V25.6H42.4286C42.0118 25.6 41.6121 25.4314 41.3174 25.1314C41.0227 24.8313 40.8571 24.4243 40.8571 24V3.2H4.71429C4.29752 3.2 3.89782 3.36857 3.60312 3.66863C3.30842 3.96869 3.14286 4.37566 3.14286 4.8V75.2C3.14286 75.6244 3.30842 76.0313 3.60312 76.3314C3.89782 76.6314 4.29752 76.8 4.71429 76.8ZM31.915 62.0357C32.198 62.3322 32.5901 62.5 33 62.5C33.4099 62.5 33.802 62.3322 34.085 62.0357L43.085 52.6071C43.657 52.0079 43.635 51.0584 43.0357 50.4864C42.4365 49.9144 41.487 49.9365 40.915 50.5357L34.5 57.2562V39C34.5 38.1716 33.8284 37.5 33 37.5C32.1716 37.5 31.5 38.1716 31.5 39L31.5 57.2562L25.085 50.5357C24.513 49.9365 23.5635 49.9144 22.9643 50.4864C22.365 51.0584 22.343 52.0079 22.915 52.6071L31.915 62.0357Z" fill="black"/>
          </svg>
        </div>
        <div class="group-politic-items-option-text">
          <span>Política de Ética y Transparencia Empresarial</span>
        </div>
      </div>
      <div class="group-politic-items-option" @click="showPolitic(5)">
        <div class="group-politic-items-option-logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="66" height="80" viewBox="0 0 66 80" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M43.5404 0.468001L65.5404 22.868C65.6862 23.0167 65.8019 23.1932 65.8808 23.3874C65.9597 23.5817 66.0002 23.7898 66 24V75.2C66 76.473 65.5033 77.6939 64.6192 78.5941C63.7351 79.4943 62.536 80 61.2857 80H4.71429C3.46398 80 2.26488 79.4943 1.38078 78.5941C0.496682 77.6939 0 76.473 0 75.2V4.8C0 3.52696 0.496682 2.30606 1.38078 1.40589C2.26488 0.505714 3.46398 4.88872e-07 4.71429 4.88872e-07H42.4286C42.635 -0.000163801 42.8394 0.0410819 43.0302 0.121383C43.221 0.201684 43.3943 0.319466 43.5404 0.468001ZM60.6336 22.4L44 5.46V22.4H60.6336ZM4.71429 76.8H61.2857C61.7025 76.8 62.1022 76.6314 62.3969 76.3314C62.6916 76.0313 62.8571 75.6244 62.8571 75.2V25.6H42.4286C42.0118 25.6 41.6121 25.4314 41.3174 25.1314C41.0227 24.8313 40.8571 24.4243 40.8571 24V3.2H4.71429C4.29752 3.2 3.89782 3.36857 3.60312 3.66863C3.30842 3.96869 3.14286 4.37566 3.14286 4.8V75.2C3.14286 75.6244 3.30842 76.0313 3.60312 76.3314C3.89782 76.6314 4.29752 76.8 4.71429 76.8ZM31.915 62.0357C32.198 62.3322 32.5901 62.5 33 62.5C33.4099 62.5 33.802 62.3322 34.085 62.0357L43.085 52.6071C43.657 52.0079 43.635 51.0584 43.0357 50.4864C42.4365 49.9144 41.487 49.9365 40.915 50.5357L34.5 57.2562V39C34.5 38.1716 33.8284 37.5 33 37.5C32.1716 37.5 31.5 38.1716 31.5 39L31.5 57.2562L25.085 50.5357C24.513 49.9365 23.5635 49.9144 22.9643 50.4864C22.365 51.0584 22.343 52.0079 22.915 52.6071L31.915 62.0357Z" fill="black"/>
          </svg>
        </div>
        <div class="group-politic-items-option-text">
          <span>Políticas de Sagrilaft y PTEE</span>
        </div>
      </div>
      <div class="group-politic-items-option" @click="showPolitic(6)">
        <div class="group-politic-items-option-logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="66" height="80" viewBox="0 0 66 80" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M43.5404 0.468001L65.5404 22.868C65.6862 23.0167 65.8019 23.1932 65.8808 23.3874C65.9597 23.5817 66.0002 23.7898 66 24V75.2C66 76.473 65.5033 77.6939 64.6192 78.5941C63.7351 79.4943 62.536 80 61.2857 80H4.71429C3.46398 80 2.26488 79.4943 1.38078 78.5941C0.496682 77.6939 0 76.473 0 75.2V4.8C0 3.52696 0.496682 2.30606 1.38078 1.40589C2.26488 0.505714 3.46398 4.88872e-07 4.71429 4.88872e-07H42.4286C42.635 -0.000163801 42.8394 0.0410819 43.0302 0.121383C43.221 0.201684 43.3943 0.319466 43.5404 0.468001ZM60.6336 22.4L44 5.46V22.4H60.6336ZM4.71429 76.8H61.2857C61.7025 76.8 62.1022 76.6314 62.3969 76.3314C62.6916 76.0313 62.8571 75.6244 62.8571 75.2V25.6H42.4286C42.0118 25.6 41.6121 25.4314 41.3174 25.1314C41.0227 24.8313 40.8571 24.4243 40.8571 24V3.2H4.71429C4.29752 3.2 3.89782 3.36857 3.60312 3.66863C3.30842 3.96869 3.14286 4.37566 3.14286 4.8V75.2C3.14286 75.6244 3.30842 76.0313 3.60312 76.3314C3.89782 76.6314 4.29752 76.8 4.71429 76.8ZM31.915 62.0357C32.198 62.3322 32.5901 62.5 33 62.5C33.4099 62.5 33.802 62.3322 34.085 62.0357L43.085 52.6071C43.657 52.0079 43.635 51.0584 43.0357 50.4864C42.4365 49.9144 41.487 49.9365 40.915 50.5357L34.5 57.2562V39C34.5 38.1716 33.8284 37.5 33 37.5C32.1716 37.5 31.5 38.1716 31.5 39L31.5 57.2562L25.085 50.5357C24.513 49.9365 23.5635 49.9144 22.9643 50.4864C22.365 51.0584 22.343 52.0079 22.915 52.6071L31.915 62.0357Z" fill="black"/>
          </svg>
        </div>
        <div class="group-politic-items-option-text">
          <span>Línea Ética</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    showPolitic (item) {
      switch (item) {
        case 0:
          window.open('https://storage.googleapis.com/precompro/dlk-web/politicas/POLITICA-DE-PROTECCIO%CC%81N-DE-DATOS-DLK.pdf', '_blank');
          break;
        case 1:
          window.open('https://storage.googleapis.com/precompro/dlk-web/politicas/POLITICA-DE-SOSTENIBILIDAD-DLK.pdf', '_blank');
          break;
        case 2:
          window.open('https://storage.googleapis.com/precompro/dlk-web/politicas/POLI%CC%81TICA-SEGURIDAD-DE-LA-INFORMACIO%CC%81N-TIC-DLK.pdf', '_blank');
          break;
        case 3:
          window.open('https://storage.googleapis.com/precompro/dlk-web/politicas/DLK-POLITICA-DE-PROVEEDORES.pdf', '_blank');
          break;
        case 4:
          window.open('https://storage.googleapis.com/precompro/dlk-web/politicas/POLITICA-DE-ETICA-Y-TRANSPARENCIA-EMPRESARIAL-DLK.pdf', '_blank');
          break;
        case 5:
          window.open('https://storage.googleapis.com/precompro/dlk-web/politicas/POLITICAS-SAGRILAFT-Y-PTEE-DLK.pdf', '_blank');
          break;
        case 6:
          window.open('https://storage.googleapis.com/precompro/dlk-web/politicas/Proforma-Canal-de-denuncias.pdf', '_blank');
          break;
        default:
          window.open('https://storage.googleapis.com/precompro/dlk-web/politicas/POLITICA-DE-PROTECCIO%CC%81N-DE-DATOS-DLK.pdf', '_blank');
          break;
      }
    }
  }
};
</script>
<style lang="scss">
@media screen and (max-width: 1023px) {
  .group-container {
    height: auto;
  }
}
@media screen and (width: 768px) and (height: 1024px) {
  .group-container {
    height: calc(100% - 213px);
  }
}
@media screen and (max-height: 810px) {
  .group-container {
    height: auto;
  }
}
.group-politic {
  display: flex;
  align-items: center;
  flex-direction: column;
  &-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    @media screen and (width: 1024px) and (height: 768px) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    span {
      color: #000;
      font-family: 'Open Sans';
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media screen and (max-width: 1279px) {
        font-size: 32px;
      }
    }
  }
  &-items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
    max-width: 1060px;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      margin-left: 17px;
    }
    @media screen and (max-width: 1023px) {
      justify-content: center;
    }
    @media screen and (max-width: 1279px) {
      gap: 10px;
    }
    &-option {
      width: 250px;
      height: 200px;
      flex-shrink: 0;
      border-radius: 8px;
      background: #FFF;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.20);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      @media screen and (min-width: 768px) and (max-width: 1279px) {
        width: 240px;
        height: 200px;
        flex-shrink: 0;
      }
      @media screen and (max-width: 767px) {
        width: 190px;
        height: 200px;
        flex-shrink: 0;
      }
      &-logo {
        width: 66px;
        height: 80px;
        flex-shrink: 0;
      }
      &-text {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        @media screen and (max-width: 767px) {
          width: 180px;
        }
        span {
          color: #000;
          text-align: center;
          font-family: 'Open Sans';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          @media screen and (max-width: 1279px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
